import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageTerms = props => {

  return (
    <Layout {...props}>
      <Seo title='Términos y Condiciones' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Términos y Condiciones'
          subheader='
          Bienvenido a Pesoplus.app. Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de Pesoplus Argentina S.A., ubicado en Buenos Aires, Argentina.
          <br><br>
          Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones. No continúes usando Pesoplus.app si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.
          <br><br>
          <strong>Cookies:</strong><br>
          Utilizamos cookies. Al acceder a Pesoplus.app, aceptaste utilizar cookies de acuerdo con la política de privacidad de Pesoplus Argentina S.A. La mayoría de los sitios web interactivos usan cookies para permitirnos recuperar los detalles del usuario para cada visita. Las cookies se utilizan en nuestro sitio web para habilitar la funcionalidad de ciertas áreas y para que sea más fácil para las personas que visitan nuestro sitio web. Algunos de nuestros socios afiliados/publicitarios también pueden usar cookies.
          <br><br>
          <strong>Licencia:</strong><br>
          A menos que se indique lo contrario, Pesoplus Argentina S.A. y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en Pesoplus.app. Todos los derechos de propiedad intelectual están reservados. Puedes acceder desde Pesoplus.app para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.
          <br><br>
          <strong>No debes:</strong><br>
          <ul>
            <li>Republicar material de Pesoplus.app</li>
            <li>Vender, alquilar o sublicenciar material de Pesoplus.app</li>
            <li>Reproducir, duplicar o copiar material de Pesoplus.app</li>
            <li>Redistribuir contenido de Pesoplus.app</li>
          </ul>
          Este acuerdo comenzará en la fecha aquíofrecida.
          <br><br>
          <strong>Comentarios y opiniones:</strong><br>
          Partes de este sitio web ofrecen a los usuarios la oportunidad de publicar e intercambiar opiniones e información en ciertas áreas del sitio web. Pesoplus Argentina S.A. no filtra, edita, publica ni revisa los comentarios antes de su presencia en el sitio web. Los comentarios no reflejan los puntos de vista ni opiniones de Pesoplus Argentina S.A., sus agentes y/o afiliados. Los comentarios reflejan los puntos de vista y opiniones de la persona que publica sus puntos de vista y opiniones. En la medida permitida por las leyes aplicables, Pesoplus Argentina S.A. no será responsable de los comentarios ni de ninguna responsabilidad, daños o gastos causados y/o sufridos como resultado de cualquier uso y/o publicación y/o aparición de los comentarios en este sitio web.
          <br><br>
          Pesoplus Argentina S.A. se reserva el derecho de monitorear todos los comentarios y eliminar cualquier comentario que pueda considerarse inapropiado, ofensivo o que cause un incumplimiento de estos términos y condiciones.
          <br><br>
          <strong>Garantizas y declaras que:</strong><br>
          <ul>
            <li>Tienes derecho a publicar los comentarios en nuestro sitio web y tienes todas las licencias y consentimientos necesarios para hacerlo;</li>
            <li>Los comentarios no invaden ningún derecho de propiedad intelectual, incluidos, entre otros, los derechos de autor, patentes o marcas comerciales de terceros;</li>
            <li>Los comentarios no contienen ningún material difamatorio, calumnioso, ofensivo, indecente o ilegal de otro modo, que es una invasión de la privacidad</li>
            <li>Los comentarios no se utilizarán para solicitar o promover negocios o personalizar o presentar actividades comerciales o ilegales.</li>
          </ul>
          Por la presente, otorgas a Pesoplus Argentina S.A. una licencia no exclusiva para usar, reproducir, editar y autorizar a otros a usar, reproducir y editar cualquiera de tus comentarios en todas y cada una de las formas, formatos o medios.
          <br><br>
          <strong>Hipervínculos a nuestro contenido:</strong><br>
          Las siguientes organizaciones pueden enlazar a nuestro sitio web sin aprobación previa por escrito:
          <ul>
            <li>Agencias gubernamentales;</li>
            <li>Motores de búsqueda;</li>
            <li>Organizaciones de noticias;</li>
            <li>Los distribuidores de directorios en línea pueden enlazar a nuestro sitio web de la misma manera que hacen hipervínculos a los sitios web de otras empresas listadas; y</li>
            <li>Empresas acreditadas en todo el sistema, excepto organizaciones sin fines de lucro, centros comerciales de caridad y grupos de recaudación de fondos de caridad que no pueden enlazar a nuestro sitio web.</li>
          </ul>
          Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones o a otra información del sitio web siempre que el enlace: (a) no sea de ninguna manera engañoso; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte que enlaza y sus productos y/o servicios; y (c) encaje dentro del contexto del sitio de la parte que enlaza.
          <br><br>
          Podemos considerar y aprobar otras solicitudes de enlace de los siguientes tipos de organizaciones:
          <ul>
            <li>fuentes de información de consumidores y/o negocios comúnmente conocidas;</li>
            <li>sitios de la comunidad punto.com;</li>
            <li>asociaciones u otros grupos que representen organizaciones benéficas;</li>
            <li>distribuidores de directorios en línea;</li>
            <li>portales de internet;</li>
            <li>firmas de contabilidad, derecho y consultoría; y</li>
            <li>instituciones educativas y asociaciones comerciales.</li>
          </ul>
          Aprobaremos las solicitudes de enlace de estas organizaciones si decidimos que: (a) el enlace no nos haría ver desfavorablemente a nosotros ni a nuestras empresas acreditadas; (b) la organización no tiene registros negativos con nosotros; (c) el beneficio para nosotros de la visibilidad del hipervínculo compensa la ausencia de Pesoplus Argentina S.A.; y (d) el enlace está en el contexto de información general de recursos.
          <br><br>
          Estas organizaciones pueden enlazar a nuestra página de inicio siempre que el enlace: (a) no sea de ninguna manera engañoso; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte que enlaza y sus productos o servicios; y (c) encaje dentro del contexto del sitio de la parte que enlaza.
          <br><br>
          Si eres una de las organizaciones enumeradas en el párrafo 2 anterior y estás interesada en enlazar a nuestro sitio web, debes informarnos enviando un correo electrónico a Pesoplus Argentina S.A. Incluye tu nombre, el nombre de tu organización, información de contacto así como la URL de tu sitio, una lista de las URL desde las que tienes la intención de enlazar a nuestro sitio web, y una lista de las URL en nuestro sitio a las que te gustaría enlazar. Espera 2-3 semanas para una respuesta.
          <br><br>
          Las organizaciones aprobadas pueden hacer hipervínculo a nuestro sitio web de la siguiente manera:
          <ul>
            <li>Mediante el uso de nuestro nombre corporativo; o</li>
            <li>Mediante el uso del localizador uniforme de recursos al que se está vinculando; o</li>
            <li>Mediante el uso de cualquier otra descripción de nuestro sitio web que tenga sentido dentro del contexto y formato del contenido en el sitio de la parte que enlaza.</li>
          </ul>
          No se permitirá el uso del logotipo de Pesoplus Argentina S.A. u otro material gráfico para vincular sin un acuerdo de licencia de marca comercial.
          <br><br>
          <strong>Responsabilidad del contenido:</strong><br>
          No seremos responsables de ningún contenido que aparezca en tu sitio web. Aceptas protegernos y defendernos contra todas las reclamaciones que están surgiendo en tu sitio web. Ningún enlace(s) debe aparecer en cualquier sitio web que pueda interpretarse como difamatorio, obsceno o criminal, o que infrinja, viole o defienda la infracción u otra violación de, cualquier derecho de terceros.
          <br><br>
          <strong>Reserva de derechos:</strong><br>
          Nos reservamos el derecho de solicitar que elimines todos los enlaces o cualquier enlace en particular a nuestro sitio web. Aceptas eliminar de inmediato todos los enlaces a nuestro sitio web cuando lo solicite. También nos reservamos el derecho de modificar estos términos y condiciones y su política de vinculación en cualquier momento. Al continuar enlazando a nuestro sitio web, aceptas estar sujeto y seguir estos términos y condiciones de vinculación.
          <br><br>
          <strong>Eliminación de enlaces de nuestro sitio web:</strong><br>
          Si encuentras algún enlace en nuestro sitio web que sea ofensivo por cualquier motivo, eres libre de contactar y avisarnos en cualquier momento. Consideraremos solicitudes para eliminar enlaces, pero no estamos obligados a hacerlo ni a responderte directamente.
          <br><br>
          No aseguramos que la información en este sitio web sea correcta, no garantizamos su integridad o exactitud; ni prometemos asegurarnos de que el sitio web permanezca disponible o que el material en el sitio web se mantenga actualizado.
          <br><br>
          <strong>Descargo de responsabilidad:</strong><br>
          En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones relacionadas con nuestro sitio web y el uso de este sitio web. Nada en este descargo de responsabilidad:
          <ul>
            <li>limitará o excluirá nuestra o tu responsabilidad por muerte o lesiones personales;</li>
            <li>limitará o excluirá nuestra o tu responsabilidad por fraude o tergiversación fraudulenta;</li>
            <li>limitará cualquiera de nuestras o tus responsabilidades de cualquier manera que no esté permitida por la ley aplicable; o</li>
            <li>excluirá cualquiera de nuestras o tus responsabilidades que no puedan ser excluidas bajo la ley aplicable.</li>
          </ul>
          Las limitaciones y prohibiciones de responsabilidad establecidas en esta Sección y en otras partes de este descargo de responsabilidad: (a) están sujetas al párrafo anterior; y (b) rigen todas las responsabilidades que surjan bajo el descargo de responsabilidad, incluidas las responsabilidades que surjan en el contrato, en agravio y por el incumplimiento de la obligación legal.
          <br><br>
          Mientras el sitio web y la información y los servicios en el sitio web se proporcionan de forma gratuita, no seremos responsables de ninguna pérdida o daño de cualquier naturaleza.
          <br><br>
          Si tienes alguna pregunta sobre nuestros términos y condiciones, no dudes en ponerte en contacto con nosotros en info@pesoplus.app.
          '
        />
      </Stack>
      <Divider />
      <Main>
      </Main>
    </Layout>
  )
}

export default PageTerms
